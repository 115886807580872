<template>
  <div class="main-container">
    <TheMap @teleportPlayer="player.teleportPlayer" :map="map" />
    <TheControls @move="player.movePlayer" />
  </div>
</template>

<script setup>
  import TheMap from './components/TheMap.vue'
  import TheControls from './components/TheControls.vue'
  import usePlayer from './composables/usePlayer.js'
  import { ref } from 'vue'


  const map = ref([
    new Array(20).fill(0),
    new Array(20).fill(0),
    new Array(20).fill(0),
    new Array(20).fill(0),
    new Array(20).fill(0),
    new Array(20).fill(0),
    new Array(20).fill(0),
    new Array(20).fill(0),
  ])


  const player = usePlayer(map)
  
  player.setPosition(1, 2)

</script>

<style>
.main-container, #app, body, html {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>

