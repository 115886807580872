<template>
  <div class="map">
      <div class="row" v-for="(row, x) in map" :key="row" >
          <div @click="$emit('teleportPlayer', [x, y])" v-for="(tile, y) in row" :key="tile" class="tile" :class="`robot-${tile}`"></div>
      </div>
  </div>
</template>

<script setup>
import { onMounted, toRefs } from 'vue'
import image from '../assets/map.png'

const props = defineProps({
    map: {
        type: Array,
    }
})

const { map } = toRefs(props)
 
</script>

<style scoped>


    .map {
        margin-right: 1rem;
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;
        font-size: .9rem;

        width: auto;

        display: flex;
        flex-direction: column;
        align-content: space-around;
        justify-content: space-around;

        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../assets/map.png);

        min-width: 768px;
    }


    .row {
        display: flex;
        flex-direction: row;

        width: 100%;
        height: 100%;

        justify-content: space-between;
    }

    .tile {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: none;

        width: 10%;
        aspect-ratio: 1/1;

        /* border: solid .01em;
        border-color: darkred; */

        color: white;
        font-weight: 900;
    }

    .robot-1 {
        background-image: url(../assets/robot.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13); 
        box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13);
        
    }
    .robot-2 {
        
        background-image: url(../assets/robot-back.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13); 
        box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13);
    }
    .robot-3 {
        
        background-image: url(../assets/robot-side.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13); 
        box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13);
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
    .robot-4 {
        
        background-image: url(../assets/robot-side.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13); 
        box-shadow: 0px 0px 39px 6px rgba(0,0,0,0.13);
    }



    .direction {
        background-color: red;
    }

</style>
