import { ref } from 'vue'

export default function(map) {

    const lastPosition = ref([0, 0])
    const position = ref([0, 0])
    let direction = [1, 0]

    const setPosition = (x, y) => {



        lastPosition.value = position.value
        position.value = [x, y]
        updatePlayer()
    }

    const teleportPlayer = ([x, y]) => {
        setPosition(x, y)
        updatePlayer()
    }

    const movePlayer = (action) => {
        let x = 0,
            y = 0
        const [px, py] = position.value
        let futurePos = [0, 0]

        // TODO COLLISION

        switch (action) {
            case 'forward':
                futurePos = [px + direction[0], py + direction[1]]

                break;
            case 'backward':
                futurePos = [px - direction[0], py - direction[1]]
                break;
            case 'turn-left':
                switch (direction.join()) {
                    case '1,0':
                        direction = [0, -1]
                        break
                    case '0,1':
                        direction = [1, 0]
                        break
                    case '-1,0':
                        direction = [0, 1]
                        break
                    case '0,-1':
                        direction = [-1, 0]
                        break
                }
                futurePos = [px, py]
                break;
            case 'turn-right':
                switch (direction.join()) {
                    case '1,0':
                        direction = [0, 1]
                        break
                    case '0,1':
                        direction = [-1, 0]
                        break
                    case '-1,0':
                        direction = [0, -1]
                        break
                    case '0,-1':
                        direction = [1, 0]
                        break
                }
                futurePos = [px, py]
                break;
        }
        console.log(direction.join())
        setPosition(futurePos[0], futurePos[1])
        updatePlayer()

    }

    const updatePlayer = function() {

        map.value = [
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
            new Array(19).fill(0),
        ]

        let [x, y] = position.value
        let [dx, dy] = [x + direction[0], y + direction[1]]

        let dirIdx = () => {
            switch (direction.join()) {
                case '1,0':
                    return 1
                    break
                case '0,1':
                    return 4
                    break
                case '-1,0':
                    return 2
                    break
                case '0,-1':
                    return 3
                    break
            }
        }

        map.value[x][y] = dirIdx()

    }

    return {
        position,
        setPosition,
        movePlayer,
        updatePlayer,
        teleportPlayer
    }

}